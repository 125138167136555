blue-r = #0063FF


body
  background #fff
  font-family: GothamPro
  margin 0

a
  cursor pointer
  color black

.container
  margin 0 auto
  max-width 1920px
  width 100%
  position relative


.main
  background url('/static/dist/img/png/main-bg.png') no-repeat
  background-size cover
  width 100%
  height 100%
  @media (max-width 768px)
    background none !important
  .main-mask
    background linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    width 100%
    height 100%
    .main-container
      height 100%
      width 500px
      margin-left 255px
      display flex
      align-items center
      @media (max-width 1280px)
        margin-left 0
        height auto
        width auto
        padding-top 100px
      .content
        display flex
        flex-direction column
        @media (max-width 1024px)
          margin-top 60px
          align-items center
        img
          @media (max-width 1280px)
            max-width 70%
          @media (max-width 768px)
            max-width 65%
          @media (max-width 650px)
            max-width 50%
        a
          display flex
          border: 2px solid blue-r;
          box-sizing: border-box;
          border-radius: 100px;
          width 320px
          text-decoration none
          font-size 28px
          span
            margin 32px auto
            display flex
            align-items center
            img
              margin-right 16px
          &:hover
            opacity 0.7
          &.films
            margin-bottom 48px
            margin-top 150px
            background blue-r;
            color white
            @media (max-width 1024px)
              margin-top 30px
          &.events
            color blue-r;
.container-movie
  border-bottom 1px solid rgba(0,0,0,.1)
.left-line
  position absolute
  right 0
  height 1080px
  @media (max-width 1440px)
    right 0
  @media (max-width 1280px)
    //position relative
    height auto
    //width 100%
    top 0

  @media (max-width 1200px)
    width 100%
  &.left-line-movie
    position fixed
    height calc(100% - 100px)
    z-index 1
    @media (max-width 1024px)
      position relative
      height auto
  .blocks
    display flex
    align-content space-between
    flex-direction column
    justify-content space-between
    align-items center
    height 100%
    @media (max-width 1280px)
      height auto
      display flex
      margin-top 32px
      align-items center
    .mouse
      margin-bottom 45px
      display flex
      flex-direction column
      @media (max-width 1280px)
        display none
    .logo
      transform rotate(-90deg)
      margin-top 50%
      z-index 1
      @media (max-width 1100px)
        transform none
        margin-top 16px
      a
        img
          width 177px
.film-container
  font-family ProximaNova
  font-weight 400
  //@media (max-width 1280px)
  //  margin-top 150px
  &.events-container
    @media (max-width 720px)
      margin-top 60px
  p
    margin 0
  .film
    display flex
    flex-direction column
    margin 0 72px
    height 100%
    @media (max-width 1600px)
      margin 0 36px

    @media (max-height 1280px)
      height inherit
    @media (max-height 950px)
      height 100%
      margin-bottom 32px
      //overflow hidden
    &.film-section
      height auto
      margin-bottom 32px
    .top-content-movie
      display flex
      flex-direction row
      margin-top 40px
      position relative
      margin-bottom 40px
      @media (max-width 1024px)
        flex-direction column
      .posters-wrap
        margin-left 5%
        position relative
        @media (max-width 1024px)
          margin-left 0
        .posterrs
          position absolute
          @media (max-width 1024px)
            position relative
            margin-top 32px
          .posters
            .poster
              border-radius 5px
              width 100px
              height 100px
              margin-bottom 16px
              @media (max-width 1024px)
                width 90%
          .tns-nav
            display none
          .tns-controls
            button
              width 40px
              height 40px
              border-radius 20px
              border none
              font-size 0
              margin-left 20px
              position absolute
              z-index 1
              left 10px
              &[data-controls="prev"]
                top -20px
                background url('/static/dist/img/svg/arrow-mini.svg')
                background-size cover
                transform rotate(180deg)
                @media (max-width 1280px)
                  bottom calc(50% - 10px)
                  top auto
                  left -40px
                  right auto
                  transform rotate(90deg)
              &[data-controls="next"]
                top auto
                bottom -6px
                background url('/static/dist/img/svg/arrow-mini.svg')
                background-size cover
                @media (max-width 1280px)
                  bottom calc(50% - 10px)
                  right -20px
                  left auto
                  transform rotate(-90deg)
              &[disabled]
               display none
              &:focus
                border-radius 32px
                outline none
      .cover
        width 30%
        margin-right 32px
        @media (max-width 1024px)
          width auto
          margin-right 0
        img
          width 100%
      .info
        width 45%
        @media (max-width 1024px)
          width auto
        .about
          .characteristics.characteristic-movie
            flex-direction rowfilm-section
            margin-top 32px
            .characteristic
              display flex
              flex-direction row
              .key
                width 30%
                font-weight 700
                //line-height 32px
                @media (max-width 1024px)
                  width 50%
              .value
                width 70%
                //line-height 32px
                @media (max-width 1024px)
                  width 50%
          .trailers-wrap
            position relative
            margin-top 12px
            p.description
              font-size 24px
              line-height 29px
              font-weight 700
              font-family ProximaNovaSemibold
            .trailers
              width 100%
              a
                position relative
                img
                  margin-left 5px
                  margin-right 5px
                  width calc(100% - 10px)
                  border-radius 0
                  max-height 220px
                  object-fit cover
                &::before
                  background url('/static/dist/img/svg/play.svg')
                  width 60px
                  height 60px
                  content ''
                  position absolute
                  top calc(50% - 30px)
                  left calc(50% - 30px)
            .tns-nav
              display none
            .tns-controls
              button
                width 40px
                height 40px
                border-radius 20px
                border none
                font-size 0
                position absolute
                left 0
                top 50%
                z-index 1
                @media (max-width 1280px)
                  width 30px
                  height 30px
                &[data-controls="prev"]
                  background url('/static/dist/img/svg/arrow-mini.svg')
                  background-size cover
                  transform rotate(90deg)
                  left -20px
                &[data-controls="next"]
                  background url('/static/dist/img/svg/arrow-mini.svg')
                  background-size cover
                  transform rotate(-90deg)
                  right -20px
                  left auto

                &[disabled]
                 display none
                &:focus
                  border-radius 32px
                  outline none
          p.date
            opacity .4
            font-size 16px
            line-height 19px
          p.title
            font-size 42px
            font-weight 700
            //line-height 58px
            font-family ProximaNovaBold
            @media (max-width 1440px)
              font-size 36px
              line-height 30px
            @media (max-width 1280px)
              margin-top 20px
          p.tagline
            font-size 18px
            font-weight 700
            //line-height 31px
            font-family ProximaNovaSemibold
          .description
            margin-top 10px
            @media (max-width 1280px)
              margin-top 20px
              margin-bottom 20px
            p
              font-size 15px
              //line-height 25px
              width 100%
            p.description
              font-size 24px
              line-height 29px
              font-weight 700
              font-family ProximaNovaSemibold
          .characteristics
            display flex
            flex-direction column
            &.characteristic-movie
              @media (max-width 1280px)
                display flex
                flex-direction column
            .actors
              font-family ProximaNovaSemibold
              p
                font-size: 16px;
                line-height: 19px;
                margin-bottom 10px
              p.actor
                opacity .4
            .first, .second
              //width 50%
              width 100%
              display flex
              flex-direction column
              @media (max-width 1280px)
                width auto
              .characteristic
                display flex
                flex-direction row
                //margin-bottom 10px
                font-size 14px
                line-height 18px
                font-family ProximaNovaSemibold
                .key
                  width 200px
                  margin-right 10px
                  @media (max-width 1280px)
                    width 50%
                .value
                  opacity .4
                  //width 300px
                  //@media (max-width 1280px)
                  //  width 50%


    .top-content
      height 65%
      margin-bottom 22px
      //width 1600px
      &.events
        //height 553px
        @media (max-width 1280px)
          height auto
          //max-height inherit
      @media (max-height 950px)
        //height 700px
        //max-height 60%
      @media (max-width: 1280px)
        height auto
        width auto
      .info
        display flex
        flex-direction row
        margin-top 48px
        height calc(100% - 48px)
        position relative
        max-width 90%
        @media (max-width 1280px)
          flex-direction column
          height auto
        .cover
          //border-radius 30px
          width: 511px
          margin-right 32px
          box-shadow 0px 20px 20px -20px rgba(0,0,0,0.5)
          @media (max-width 1920px)
            width 27%
          @media (max-width 1440px)
            width 25%
          @media (max-width 1366px)
            width 20%
          @media (max-width 1280px)
            width 100%
            min-height 400px
          &.cover-event
            width 40%
            height 100%
            @media (max-width 1600px)
              width 37%
            @media (max-width 1440px)
              width 35%
            @media (max-width 1366px)
              width 30%
            @media (max-width 1280px)
              width 100%
              min-height 400px
        .about
          position relative
          width 50%
          //@media (max-width 1366px)
          //  width 42%
          //@media (max-width 1280px)
          //  width 100%
          //&.about-event
          //  @media (max-width 1440px)
          //    width 40%
          //  @media (max-width 1366px)
          //    width 50%
          @media (max-width 1280px)
            width auto
            margin-top 32px
          .b-content
            position absolute
            bottom 0
            width 100%
            @media (max-width 1280px)
              position relative
            p
              font-size 24px
              font-family ProximaNovaSemibold
              margin-bottom 30px
          p.date
            opacity .4
            font-size 16px
            line-height 19px
          p.title
            font-size 48px
            font-weight 700
            line-height 40px
            font-family ProximaNovaBold
            @media (max-width 1440px)
              font-size 36px
              line-height 30px
            @media (max-width 1280px)
              margin-top 20px
          p.tagline
            font-size 18px
            font-weight 700
            line-height 31px
            font-family ProximaNovaSemibold
          .description
            margin-top 2%
            @media (max-width 1280px)
              margin-top 20px
              margin-bottom 20px
            p
              font-size 18px
              line-height 22px
              width 100%
            p.description
              font-size 24px
              line-height 29px
              font-weight 700
              font-family ProximaNovaSemibold
          .characteristics
            display flex
            flex-direction row
            position absolute
            bottom 0
            @media (max-width 1280px)
              position relative
              flex-direction column
            .actors
              font-family ProximaNovaSemibold
              p
                font-size: 16px;
                line-height: 19px;
                margin-bottom 10px
              p.actor
                opacity .4
            .first
              width 70%
              display flex
              flex-direction column
              @media (max-width 1280px)
                width auto
              .characteristic
                display flex
                flex-direction row
                margin-bottom 10px
                font-size 16px
                line-height 19px
                font-family ProximaNovaSemibold
                .key
                  width 200px
                  margin-right 10px
                  @media (max-width 1280px)
                    width 50%
                .value
                  opacity .4
                  width 300px
                  @media (max-width 1280px)
                    width 50%
      .posters-wrap, .posters-wrap-events
        position relative
        margin-left 5%
        .posters
          display flex
          flex-direction column
          @media (max-width 1280px)
            flex-direction row
          a
            .poster
              border-radius 5px
              width 100px
              height 100px
              margin-bottom 16px
        .tns-nav
          display none
        .tns-controls
          button
            width 40px
            height 40px
            border-radius 20px
            border none
            font-size 0
            position absolute
            left calc(50% - 20px)
            z-index 1
            @media (max-width 1280px)
              width 30px
              height 30px
            &[data-controls="prev"]
              top -20px
              background url('/static/dist/img/svg/arrow-mini.svg')
              background-size cover
              transform rotate(180deg)
              @media (max-width 1280px)
                bottom 50%
                right auto
                top auto
                left -10px
                transform rotate(90deg)
            &[data-controls="next"]
              top auto
              bottom -6px
              background url('/static/dist/img/svg/arrow-mini.svg')
              background-size cover
              @media (max-width 1600px)
                bottom 70px
              @media (max-width 1280px)
                bottom 50%
                right 0
                left auto
                transform rotate(-90deg)
            &[disabled]
             display none
            &:focus
              border-radius 32px
              outline none
      .posters-wrap-events
        width 100%
        position relative
        margin-left 0px
        @media (max-width 1280px)
          margin-left 0
          width 100%
        p
          font-family ProximaNovaSemibold
          font-size 24px
          margin-bottom 30px
        .posters
          display flex
          flex-direction row
          justify-content space-between
          a
            width 25%
            .poster
              width 162px
              height 162px
              margin-bottom 0
              @media (max-width 1440px)
                width 150px
                height 150px
              @media (max-width 1366px)
                width 130px
                height 130px
              @media (max-width 1280px)
                width 100px
                height 100px

        .tns-controls
          button
            &[data-controls="prev"]
              background url('/static/dist/img/svg/arrow-mini.svg') center center
              transform rotate(90deg)
              top calc(50% - 20px)
              left -20px

            &[data-controls="next"]
              top calc(50% - 20px)
              left auto
              right -20px
              background url('/static/dist/img/svg/arrow-mini.svg') center center
              transform rotate(-90deg)
    .bb-content
      //position absolute
      bottom 32px
      @media (max-width 1280px)
        position relative
        bottom auto
      p
        font-family ProximaNovaBold
        font-size 32px
        margin-bottom 30px
        @media (max-width 1280px)
          font-size 24px
          margin-bottom 26px
    .bottom-content
      position relative
      height 30%
      width 85%
      //max-height 350px
      //max-width 440px
      @media (max-width 1280px)
        width auto
      p
        font-family ProximaNovaBold
        font-size 32px
      .trailers
        width 100% !important
        a
          position relative
          width auto !important
          img
            border-radius 20px
            height 80%
            min-height 240px
            @media (max-width 1600px)
              height 70%
            @media (max-width 1440px)
              height 66%
            @media (max-width 1366px)
              height 55%
            @media (max-width 1280px)
              height auto
              border-radius 0
              margin 0 auto
              width 90%
            //&.video
            //  height auto
            //  width 90%
            //  border-radius 30px
          @media (max-width 1280px)
            text-align center
          &::before
            background url('/static/dist/img/svg/play.svg')
            width 60px
            height 60px
            content ''
            position absolute
            top calc(50% - 30px)
            left calc(50% - 30px)
      .tns-nav
        display none
      .tns-controls
        button
          width 64px
          height 64px
          border-radius 32px
          font-size 0
          position absolute
          top 30%
          z-index 1
          @media (max-width 1366px)
            width 50px
            height 50px
            top 20%
          @media (max-width 1280px)
            top calc(50% - 15px)
            width 30px
            height 30px
          &[data-controls="prev"]
            left -32px
            background url('/static/dist/img/svg/arrow.svg')
            background-size cover
            border none
            transform rotate(180deg)
            @media (max-width 1280px)
              left auto
          &[data-controls="next"]
            right 0
            background url('/static/dist/img/svg/arrow.svg')
            background-size cover
            border none
            @media (max-width 1366px)
              right 0
              //top calc(100% - 6px) !important
            @media (max-width 1280px)
              right 0px
              //top calc(100% - 6px) !important

          &[disabled]
           display none
          &:focus
            border-radius 32px
            outline none